import React, { Component } from 'react'
import { Col, Container, Row, Button, Alert } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { useNavigate, useParams } from 'react-router-dom'
import Header from './components/Header'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

interface CampaignPaymentProps {
  navigate: any
  params: any
}

type CampaignPaymentStates = {
  campaign: any
  stripe: any
  stripeSecret: any
  amount: any
  isLoading: boolean
  success: boolean
}

class CampaignPayment extends Component <CampaignPaymentProps, CampaignPaymentStates> {
  constructor (props: CampaignPaymentProps) {
    super(props)
    this.state = {
      campaign: undefined,
      stripe: undefined,
      stripeSecret: undefined,
      amount: '',
      isLoading: false,
      success: false
    }
  }

  componentDidMount () {
    this.loadDashboard()
    this.loadPayment()
  }

  loadDashboard = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaign/'+this.props.params.id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        this.setState({campaign: json.campaign})
      })
  }

  loadPayment = (e: any = undefined) => {
    if (e !== undefined) {
      e.preventDefault()
    }
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/payment/'+ this.props.params.id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const stripe = await loadStripe(json.stripeKey)
          this.setState({
            stripe,
            stripeSecret: json.clientSecret,
            amount: json.amount
          })
        }
        if (json.status === 'nouser') {
          this.props.navigate('/sign-in')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Campaign</Header.Pretitle>
                        <Header.Title className="text-truncate">{this.state.campaign?.name}</Header.Title>
                        <div className='small text-muted pt-2'>{this.state.campaign?.description}</div>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2 mb-7">
              { this.state.success === false ?
                <Col>
                  <div className='text-center'>
                    <h1 className='mb-5'>Campaign Payment</h1>
                  </div>
                  { this.state.stripe && this.state.stripeSecret ?
                    <EmbeddedCheckoutProvider
                      stripe={this.state.stripe}
                      options={{
                        clientSecret: this.state.stripeSecret,
                        onComplete: ()=>this.setState({success: true})
                      }}
                    >
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                  : null}
                </Col>
              :
                <Col sm={12} md={8} lg={8} xl={6}>
                  <div className='text-center'>
                    <h1 className='mb-7'>Campaign Payment</h1>
                  </div>
                  <Alert variant="success" className='text-center'>
                    <div className='pt-5 pb-5'>
                      <div>Payment was success.</div>
                      <div>It may take up to few minutes for campaign to be ready.</div>
                    </div>
                    <Button onClick={()=>this.props.navigate('/a/campaign/'+this.props.params.id)}>
                      Back to Campaign
                    </Button>
                  </Alert>
                </Col>
              }
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function CampaignPaymentWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <CampaignPayment navigate={navigate} params={params}/>
}