import React, { Component } from 'react'
import { Card, Row, Col, Container, Button, ListGroup, Dropdown, Modal, Form, Alert } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import FeatherIcon from 'feather-icons-react'
import Header from './components/Header'
import Dropzone from './components/Dropzone'
import axios from 'axios'

interface VerifyViewProps {
  navigate: any
  params: any
}

type VerifyViewStates = {
  publisher: any
  publishers: any[]
  modalOpen: boolean
  modalType: string
  modalTitle: string
  modalFileOrText: any
  modalIsLoading: boolean
  modalError: string
}

class VerifyView extends Component <VerifyViewProps, VerifyViewStates> {
  constructor (props: VerifyViewProps) {
    super(props)
    this.state = {
      publisher: undefined,
      publishers: [],
      modalOpen: false,
      modalType: '',
      modalTitle: '',
      modalFileOrText: undefined,
      modalIsLoading: false,
      modalError: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as VerifyViewStates)
  }

  componentDidMount () {
    // this.loadVerify()
    this.loadPublishers()
  }

  loadPublishers = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publishers.length > 0) {
            if (this.props.params.id === 'xxxxx') {
              this.props.navigate('/p/' + json.publishers[0].id + '/verify')
            } else {
              this.setState({
                publishers: json.publishers
              }, () => {
                this.loadVerify()
              })
            }
          } else {
            this.props.navigate('/create-publisher')
          }
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  componentDidUpdate(prevProps: Readonly<VerifyViewProps>, prevState: Readonly<VerifyViewStates>, snapshot?: any): void {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadVerify()
    }
  }

  loadVerify = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verify', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publisher.verified === "verified") {
            this.props.navigate('/p/' + this.props.params.id + '/withdrawals')
            return
          }
          this.setState({
            publisher: json.publisher
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  addItem = async () => {
    this.setState({modalIsLoading: true, modalError: ''})
    const { token } = getCredentials()
    const data: any = {
      'title': this.state.modalTitle,
      'type': this.state.modalType,
    }
    if (this.state.modalType === 'Image item' || this.state.modalType === 'File item') {
      data.file = this.state.modalFileOrText
    } else {
      data.text = this.state.modalFileOrText
    }
    const result = await axios.postForm(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verifyasset',
      data,
      {headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      }}
    );
    if (result.status === 200 && result.data.status === "success") {
      this.setState({modalIsLoading: false, modalOpen: false, modalFileOrText: undefined, modalTitle: '', modalType: ''})
      this.loadVerify()
    } else {
      this.setState({modalIsLoading: false, modalError: result.data.message})
    }
  }

  remove = async (assetId: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verifyasset/' + assetId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.loadVerify()
        }
      })
  }

  verify = async () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verify/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.loadVerify()
        }
      })
  }

  render() {
    const { token } = getCredentials()
    return (
      <Dash>
        <div className="main-content">
          <Header>
            <Container fluid>
              <Header.Body>
                <Row className="align-items-end">
                  <Col>
                    <Header.Pretitle as="h6">Publisher verification</Header.Pretitle>
                    <div className='d-flex flex-row'>
                      <Header.Title as="h1">
                        {this.state.publisher?.name} - {this.state.publisher?.verified}
                      </Header.Title>
                      <Dropdown>
                        <Dropdown.Toggle role="menu" variant='link' style={{paddingTop: 3, paddingLeft: 5}}></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.publishers.map((publisher: any)=>{
                            return <Dropdown.Item as={Link} to={'/p/'+publisher.id+'/verify'} key={publisher.id}>{publisher.name}</Dropdown.Item>
                          })}
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item as={Link} to="/create-publisher" key="newpublisher">New Publisher</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col xs="auto">
                    { this.state.publisher?.verified === "unverified" ?
                      <Button variant="primary" onClick={()=>this.verify()}>
                        Send Verify
                      </Button>
                    : null}
                  </Col>
                </Row>
              </Header.Body>
            </Container>
          </Header>
          <Container fluid>
            <Row>
              <Col xs={12}>

                <h2 className="pb-3">Documents for KYC/KYB are reqired for withdrawal</h2>

                <div className="pb-4">
                  <h3>From Company (legal entity):</h3>
                  <ul>
                    <li>Registered name</li>
                    <li>Email</li>
                    <li>Registered address of the entitys headquarters</li>
                    <li>Company number</li>
                  </ul>
                </div>

                <div className="pb-4">
                  <h3>For each legal representative and ultimate beneficial owner:</h3>
                  <ul>
                    <li>Government issued ID for identity validation</li>
                    <li>Utility/Internet bill for address validation</li>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Address</li>
                    <li>Nationality</li>
                    <li>Date of birth</li>
                    <li>Birthplace city and country</li>
                  </ul>
                </div>

                <Card>
                  <Card.Header>
                    <h2 className="card-header-title">Documents</h2>
                    { this.state.publisher?.verified === "unverified" ?
                      <Button onClick={()=>this.setState({modalOpen: true})}>Add</Button>
                    :null}
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-lg list-group-flush my-n4">
                      {this.state.publisher && this.state.publisher.verifications.map((verification: any, i: number) => {
                        return (
                          <ListGroup.Item key={verification.id}>
                            <Row className="align-items-center">
                              <Col className="ms-n2">
                                <h4 className="mb-1">
                                  {verification.title}
                                </h4>
                              </Col>
                              <Col xs="auto">
                                {verification.type === 'Image item' ?
                                  <a href={config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verifyfile/' +  verification.data + '?key=' + token} target='blank'><img height={100} src={config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verifyfile/' +  verification.data + '?key=' + token} alt=""/></a>
                                : null}
                                {verification.type === 'Text item' ?
                                  <Card.Text className="small text-muted mb-1">{verification.data}</Card.Text>
                                : null}
                                {verification.type === 'File item' ?
                                  <a href={config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/verifyfile/' +  verification.data + '?key=' + token} target='blank'>Download file</a>
                                : null}
                              </Col>
                              <Col xs="auto">
                                <Dropdown align="end">
                                  <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                                    <FeatherIcon icon="more-vertical" size="17" />
                                  </Dropdown.Toggle>
                                  { this.state.publisher?.verified === "unverified" ?
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={()=>this.remove(verification.id)}>Remove</Dropdown.Item>
                                    </Dropdown.Menu>
                                  :null}
                                </Dropdown>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                      {this.state.publisher && this.state.publisher.verifications.length === 0 ?
                        <ListGroup.Item className="pb-5 pt-5 text-center">No documents yet</ListGroup.Item>
                      : null}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal show={this.state.modalOpen} centered onHide={()=>this.setState({modalOpen: false})}>
          <Modal.Header>
            <Modal.Title>Add document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" value={this.state.modalTitle} name="modalTitle" onChange={this.inputChange} placeholder="Address, photo of xyz, document of xyz..." />
            </div>
            <Dropdown className="pb-4">
              <Dropdown.Toggle>
                {this.state.modalType ? this.state.modalType : 'File type'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>this.setState({modalType: 'Text item', modalFileOrText: undefined})}>Text</Dropdown.Item>
                <Dropdown.Item onClick={()=>this.setState({modalType: 'Image item', modalFileOrText: undefined})}>Image</Dropdown.Item>
                <Dropdown.Item onClick={()=>this.setState({modalType: 'File item', modalFileOrText: undefined})}>File</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {this.state.modalType === 'Text item' ?
              <div className="form-group">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  name="modalFileOrText"
                  value={this.state.modalFileOrText}
                  onChange={this.inputChange}
                  style={{ height: '200px' }}
                />
              </div>
            :null}
            {this.state.modalType === 'Image item' ?
              <div className="form-group">
                <Form.Label className="mb-1">Image</Form.Label>
                <Dropzone
                  accept={{
                    'image/*': ['.jpeg', '.png', '.gif', '.jpg']
                  }}
                  multiple={false}
                  file={this.state.modalFileOrText !== undefined ? this.state.modalFileOrText.name : undefined}
                  onDrop={(acceptedFiles: any) => this.setState({modalFileOrText: acceptedFiles[0]})}
                />
              </div>
            :null}
            {this.state.modalType === 'File item' ?
              <div className="form-group">
                <Form.Label className="mb-1">File</Form.Label>
                <Dropzone
                  placeholder="Kra"
                  multiple={false}
                  file={this.state.modalFileOrText !== undefined ? this.state.modalFileOrText.name : undefined}
                  onDrop={(acceptedFiles: any) => this.setState({modalFileOrText: acceptedFiles[0]})}
                >
                  {({getRootProps, getInputProps}: any) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder='kra' />
                      <p>Kra</p>
                    </div>
                  )}
                </Dropzone>
              </div>
            :null}
          </Modal.Body>
          <Modal.Footer>
            { this.state.modalIsLoading === false && this.state.modalError === '' ?
              <>
                <Button variant="secondary" disabled={this.state.modalIsLoading} onClick={()=>this.setState({modalOpen: false})}>
                  Close
                </Button>
                <Button variant="primary" onClick={()=>this.addItem()}>
                  Save & Upload
                </Button>
              </>
            : null}
            { this.state.modalIsLoading === true && this.state.modalError === '' ?
              <Button disabled={true} variant="primary">
                Saving...
              </Button>
            : null}
            { this.state.modalError ?
              <>
                <Button variant="secondary" disabled={this.state.modalIsLoading} onClick={()=>this.setState({modalOpen: false})}>
                  Close
                </Button>
                <Alert variant="warning">{this.state.modalError}</Alert>
              </>
            : null}
          </Modal.Footer>
        </Modal>

      </Dash>
    );
  }
}

export default function VerifyViewWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <VerifyView navigate={navigate} params={params}/>
}