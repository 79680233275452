import React, { Component } from 'react'
import { Card, Row, Col, Container, Button, Nav, Dropdown } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import FeatherIcon from 'feather-icons-react'
import Header from './components/Header'
import { Chart } from './components/Chart'
import { Bar } from 'react-chartjs-2'

const options = {
  scales: {
    yAxisOne: {
      display: 'auto',
      ticks: {
        callback: function (value: any) {
          return '$' + (value/100);
        },
      },
    }
  },
};

interface PubDashProps {
  navigate: any
  params: any
}

type PubDashStates = {
  publishers: any[],
  publisher: any,
  graph: any[]
}

class PubDash extends Component <PubDashProps, PubDashStates> {
  constructor (props: PubDashProps) {
    super(props)
    this.state = {
      publishers: [],
      publisher: undefined,
      graph: []
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as PubDashStates)
  }

  componentDidMount () {
    this.loadPublishers()
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadPublisher()
    }
  }

  loadPublishers = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publishers.length > 0) {
            this.setState({
              publishers: json.publishers
            }, () => this.loadPublisher())
          } else {
            this.props.navigate('/create-publisher')
          }
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  loadPublisher = () => {
    const { token } = getCredentials()
    let id
    if (this.props.params.id) {
      id = this.props.params.id
    } else {
      id = this.state.publishers[this.state.publishers.length-1].id
      this.props.navigate('/p/'+id)
    }
    this.loadGraph(id)
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({
            publisher: json.publisher
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  loadGraph = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + id + '/graph', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({
            graph: json.graph
          })
        }
      })
  }

  stop = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + id + '/stop', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadPublisher()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  resume = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + id + '/resume', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadPublisher()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    const labels = this.state.graph.length > 0 ? this.state.graph.map((el)=>el.date) : [];
    const dataset = {
      data: this.state.graph.length > 0 ? this.state.graph.map((el)=>el.earned) : [],
      yAxisID: 'yAxisOne'
    }
    return (
      <Dash>
        <div className="main-content">
          <Header>
            <Container fluid>
              <Header.Body>
                <Row className="align-items-end">
                  <Col>
                    <Header.Pretitle as="h6">Publisher</Header.Pretitle>
                    <div className='d-flex flex-row'>
                      <Header.Title as="h1">
                        {this.state.publisher?.name}
                      </Header.Title>
                      <Dropdown>
                        <Dropdown.Toggle role="menu" variant='link' style={{paddingTop: 3, paddingLeft: 5}}></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.publishers.map((publisher: any)=>{
                            return <Dropdown.Item as={Link} to={'/p/'+publisher.id} key={publisher.id}>{publisher.name}</Dropdown.Item>
                          })}
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item as={Link} to="/create-publisher" key="newpublisher">New Publisher</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col xs="auto" className='d-flex flex-row'>
                    <Header.Tabs>
                      <Nav.Item>
                        <Nav.Link className="text-center" active={false} role="button">
                          <Header.Pretitle className="text-secondary">today</Header.Pretitle>
                          <h3 className="mb-0">${this.state.publisher?.earnedtoday/100}</h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Header.Tabs>
                    <Dropdown style={{paddingTop: 20, paddingLeft: 20}}>
                      <Dropdown.Toggle variant="primary">Actions</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item disabled={!(this.state.publisher?.status === 'active')} onClick={()=>this.stop(this.state.publisher?.id)}>Stop</Dropdown.Item>
                        <Dropdown.Item disabled={!(this.state.publisher?.status === 'stopped')} onClick={()=>this.resume(this.state.publisher?.id)}>Resume</Dropdown.Item>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item as={Link} to={"/p/"+this.props.params.id+"/edit"}>Edit</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Header.Body>
              <Header.Footer>
                <Chart>
                  <Bar data={{ labels: labels, datasets: [{ ...dataset }] }} options={options as any} />
                </Chart>
              </Header.Footer>
            </Container>
          </Header>
          <Container fluid>
            <Row>
              <Col xs={12} lg={4} xl={4}>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">Status</h6>
                        <span className="h2 mb-0">{this.state.publisher?.status}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="briefcase" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={4} xl={4}>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">served</h6>
                        <span className="h2 mb-0">{this.state.publisher?.count}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="briefcase" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={4} xl={4}>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">earned today</h6>
                        <span className="h2 mb-0">${this.state.publisher?.earnedtoday/100}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="dollar-sign" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function PubDashWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <PubDash navigate={navigate} params={params}/>
}