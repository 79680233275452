import React, { Component } from 'react';
import { Card, Table, Dropdown, InputGroup, Form, Col, Container, Row, Button, Nav, Badge } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import Header from './components/Header'
import Dash from './Dash'
import { getCredentials } from './utils'

interface AdminViewProps {
  navigate: any
}

type AdminViewStates = {

}

class AdminView extends Component <AdminViewProps, AdminViewStates> {
  constructor (props: AdminViewProps) {
    super(props)
    this.state = {
      search: '',
      publishers: []
    }
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Admin</Header.Pretitle>
                        <Header.Title className="text-truncate">Admin</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <div>
                  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function AdminViewWithNavigation() {
  const navigate = useNavigate()
  return <AdminView navigate={navigate}/>
}