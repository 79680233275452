import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import DropzoneAlias from 'react-dropzone';

function Dropzone({ multiple, className, ...props }: any) {
  const classes = classNames('dropzone', multiple && 'dropzone-multiple', className);

  return (
    <DropzoneAlias multiple={multiple} {...props}>
      {({ getRootProps, getInputProps }: any) => (
        <div className={classes} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dz-message">{props.file ? props.file : 'Drop item here to upload'}</div>
        </div>
      )}
    </DropzoneAlias>
  );
}

export default Dropzone;
