import React, { Component } from 'react'
import { Card, Row, Col, Container, Button, ListGroup, Dropdown, Modal, Form, Alert } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from './components/Header'
import Moment from 'react-moment'

interface WithdrawalsViewProps {
  navigate: any
  params: any
}

type WithdrawalsViewStates = {
  publisher: any
  available: number
  publishers: any[]
  modalOpen: boolean
  modalAmount: string
  modalIsLoading: boolean
  modalError: string
}

class WithdrawalsView extends Component <WithdrawalsViewProps, WithdrawalsViewStates> {
  constructor (props: WithdrawalsViewProps) {
    super(props)
    this.state = {
      publisher: undefined,
      available: 0,
      publishers: [],
      modalOpen: false,
      modalAmount: '',
      modalIsLoading: false,
      modalError: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as WithdrawalsViewStates)
  }

  componentDidMount () {
    this.loadPublishers()
  }

  loadPublishers = (load: boolean = true) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publishers.length > 0) {
            if (this.props.params.id === 'xxxxx') {
              this.props.navigate('/p/' + json.publishers[0].id + '/withdrawals')
            } else {
              this.setState({
                publishers: json.publishers
              }, () => {
                if (load) this.loadWithdrawals()
              })
            }
          } else {
            this.props.navigate('/create-publisher')
          }
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  componentDidUpdate(prevProps: Readonly<WithdrawalsViewProps>, prevState: Readonly<WithdrawalsViewStates>, snapshot?: any): void {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadWithdrawals()
      this.loadPublishers(false)
    }
  }

  loadWithdrawals = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/withdrawals', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publisher.verified !== "verified") {
            this.props.navigate('/p/' + this.props.params.id + '/verify')
            return
          }
          this.setState({
            publisher: json.publisher,
            available: json.available
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  sendRequest = async () => {
    this.setState({modalIsLoading: true, modalError: ''})
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/withdrawals', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          amount: Number(this.state.modalAmount) * 100
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({modalIsLoading: false, modalOpen: false, modalAmount: '0'})
          this.loadWithdrawals()
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
        if (json.status === "error") {
          this.setState({modalIsLoading: false, modalError: json.message})
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Header>
            <Container fluid>
              <Header.Body>
                <Row className="align-items-end">
                  <Col>
                    <Header.Pretitle as="h6">Publisher withdrawals</Header.Pretitle>
                    <div className='d-flex flex-row'>
                      <Header.Title as="h1">
                        {this.state.publisher?.name} - {this.state.publisher?.verified}
                      </Header.Title>
                      <Dropdown>
                        <Dropdown.Toggle role="menu" variant='link' style={{paddingTop: 3, paddingLeft: 5}}></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.publishers.map((publisher: any)=>{
                            return <Dropdown.Item as={Link} to={'/p/'+publisher.id+'/withdrawals'} key={publisher.id}>{publisher.name}</Dropdown.Item>
                          })}
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item as={Link} to="/create-publisher" key="newpublisher">New Publisher</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </Header.Body>
            </Container>
          </Header>
          <Container fluid>
            <Row>
              <Col xs={12}>

                <div className="pb-4">
                  <h3>Withdrawals:</h3>
                  <ul>
                    <li>Balance includes earnings that are over 1 month old</li>
                    <li>It takes up to 1 month to process withdrawals</li>
                    <li>Minimal withdrawal amount is $100</li>
                  </ul>
                </div>

                <Card>
                  <Card.Header>
                    <h2 className="card-header-title">Available: ${this.state.available/100}</h2>
                    { this.state.publisher?.verified === "verified" ?
                      <Button onClick={()=>this.setState({modalOpen: true})}>New Withdraw</Button>
                    :null}
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-lg list-group-flush my-n4">
                      {this.state.publisher && this.state.publisher.withdrawals.map((withdraw: any, i: number) => {
                        return (
                          <ListGroup.Item key={withdraw.id}>
                            <Row className="align-items-center">
                              <Col className="ms-n2">
                                <h4 className="mb-1">
                                  Requested: ${withdraw.amount/100} (${withdraw.effective/100})
                                </h4>
                              </Col>
                              <Col>
                                {withdraw.status}
                              </Col>
                              <Col xs="auto">
                                <Moment format="DD/MM/YYYY">{withdraw.createdAt}</Moment>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                      {this.state.publisher && this.state.publisher.withdrawals.length === 0 ?
                        <ListGroup.Item className="pb-5 pt-5 text-center">No withdrawals yet</ListGroup.Item>
                      : null}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal show={this.state.modalOpen} centered onHide={()=>this.setState({modalOpen: false})}>
          <Modal.Header>
            <Modal.Title>Withdrawal requests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={this.state.modalAmount} name="modalAmount" onChange={this.inputChange} placeholder="Amount to withdraw" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            { this.state.modalIsLoading === false ?
              <>
                <Button variant="secondary" disabled={this.state.modalIsLoading} onClick={()=>this.setState({modalOpen: false, modalAmount: '', modalError: '', modalIsLoading: false})}>
                  Close
                </Button>
                <Button variant="primary" onClick={()=>this.sendRequest()}>
                  Send
                </Button>
              </>
            : null}
            { this.state.modalIsLoading === true && this.state.modalError === '' ?
              <Button disabled={true} variant="primary">
                Saving...
              </Button>
            : null}
            { this.state.modalError ?
              <>
                <Alert variant="warning" style={{padding: 8}}>{this.state.modalError}</Alert>
              </>
            : null}
          </Modal.Footer>
        </Modal>

      </Dash>
    );
  }
}

export default function WithdrawalsViewWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <WithdrawalsView navigate={navigate} params={params}/>
}