import React, { Component } from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import { getCredentials } from './utils'

interface DashboardselectProps {
  navigate: any
}

type DashboardselectStates = {
}

class Dashboardselect extends Component <DashboardselectProps, DashboardselectStates> {
  constructor (props: DashboardselectProps) {
    super(props)
    this.state = {
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as DashboardselectStates)
  }

  savePick = (dashboard: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/dashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          dashboard
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          if (dashboard === 'advertiser'){
            this.props.navigate('/a/')
          }
          if (dashboard === 'publisher'){
            this.props.navigate('/p')
          }
        }
        if (json.status === 'nouser') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    return (
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5">
              <>
                <h1 className="display-4 text-center mb-3">Select dashboard</h1>
                <p className="text-muted text-center mb-5">You can always switch later in your profile.</p>
                <div>
                  <Card>
                    <Card.Header>
                      <h4 className="card-header-title">Advertiser</h4>
                    </Card.Header>
                    <Card.Body className='text-center'>
                      <Button size='lg' onClick={()=>this.savePick('advertiser')}>Enter as Advertiser</Button>
                    </Card.Body>
                  </Card>
                </div>
                <div>
                  <Card>
                    <Card.Header>
                      <h4 className="card-header-title">Publisher (Chatbot owner)</h4>
                    </Card.Header>
                    <Card.Body className='text-center'>
                      <Button size='lg' onClick={()=>this.savePick('publisher')}>Enter as Publisher</Button>
                    </Card.Body>
                  </Card>
                </div>
              </>
            </Col>
          </Row>
          <div className="text-center text-white" onClick={()=>this.props.navigate('/admin')}>Admin</div>
        </Container>
      </div>
    );
  }
}

export default function DashboardselectWithNavigation() {
  const navigate = useNavigate()
  return <Dashboardselect navigate={navigate}/>
}