import React, { Component } from 'react'
import { Alert, Col, Container, Row, Form, Card, Button } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from './components/Header'

interface EditProfileProps {
  navigate: any
  params: any
}

type EditProfileStates = {
  error: string
  success: string
  oldPassword: string
  newPassword: string
  reNewPassword: string
}

class EditProfile extends Component <EditProfileProps, EditProfileStates> {
  constructor (props: EditProfileProps) {
    super(props)
    this.state = {
      error: '',
      success: '',
      oldPassword: '',
      newPassword: '',
      reNewPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as EditProfileStates)
  }


  componentDidMount () {
  }

  changePassword = () => {
    this.setState({error: '', success: ''})
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
          reNewPassword: this.state.reNewPassword
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({success: 'Password updated'})
        }
        if (json.status === 'error') {
          this.setState({ error: json.message })
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12} lg={10} xl={8}>
                <Header className="mt-md-5">
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>profile</Header.Pretitle>
                        <Header.Title>Edit Profile</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <h2 className="mt-5">Change password</h2>
                <hr className="mt-1 mb-5" />
                <form className="mb-4">
                  <div className="form-group">
                    <Form.Label>Old password</Form.Label>
                    <Form.Control value={this.state.oldPassword} onChange={this.inputChange} name="oldPassword" type="password" />
                  </div>
                  <div className="form-group">
                    <Form.Label>New password</Form.Label>
                    <Form.Control value={this.state.newPassword} onChange={this.inputChange} name="newPassword" type="password" />
                  </div>
                  <div className="form-group">
                    <Form.Label>New password re-enter</Form.Label>
                    <Form.Control value={this.state.reNewPassword} onChange={this.inputChange} name="reNewPassword" type="password" />
                  </div>
                  { this.state.error !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({error: ''})} dismissible>
                      {this.state.error}
                    </Alert>
                  :null}
                  { this.state.success !== '' ?
                    <Alert variant="success" className='text-center' onClose={() => this.setState({success: ''})} dismissible>
                      {this.state.success}
                    </Alert>
                  :null}
                  <Button className="w-100" onClick={()=>this.changePassword()}>
                    Change password
                  </Button>
                  <Button variant="link" className="w-100 text-muted" onClick={()=>this.props.navigate('/')}>
                    Cancel
                  </Button>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function EditProfileWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <EditProfile navigate={navigate} params={params}/>
}