import React, { useState } from 'react';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Collapse, Container, Dropdown, Form, InputGroup, Nav, Navbar } from 'react-bootstrap';
import ModalNotifications from '../modals/ModalNotifications';
import ModalSearch from '../modals/ModalSearch';
import Avatar from '../components/Avatar';
import Icon from '../components/Icon';
import data from '../data/nav';
import { storeCredentials } from '../utils'

export default function Sidenav({ ...props }) {
  const location = useLocation()

  const [activeItemId, setActiveItemId] = useState(() => {
    return Object.keys(data).filter((itemId) => {
      return data[itemId].url === location.pathname;
    })[0];
  });

  const [modalSearchVisible, setModalSearchVisible] = useState(false);
  const [modalNotificationsVisible, setModalNotificationsVisible] = useState(false);

  function isExpanded(itemId: any) {
    if (activeItemId === itemId) {
      return true;
    }

    return isParent(itemId);
  }

  function isParent(itemId: any) {
    const item = data[itemId];

    if (!item.children) {
      return false;
    }

    if (item.children.includes(activeItemId)) {
      return true;
    }

    let result = false;

    item.children.forEach((childId: any) => {
      if (isParent(childId)) {
        result = true;
      }
    });

    return result;
  }

  function getItems(ids: any) {
    return ids.map(function (id: any, index: any) {
      const item = data[id];
      if (location.pathname.startsWith('/edit-profile')) {
        return null
      }
      if ((location.pathname.startsWith('/p') || location.pathname.startsWith('/create-publisher')) && (item.id.startsWith('A') || item.id.startsWith('C'))) {
        return null
      }
      if (location.pathname.startsWith('/a/') && (item.id.startsWith('P') || item.id.startsWith('C'))) {
        return null
      }
      if (location.pathname.startsWith('/admin') && (item.id.startsWith('P') || item.id.startsWith('A'))) {
        return null
      }
      return (
        <div key={id}>
          {index > 0 && <hr className="navbar-divider" />}
          {item.title && <h6 className="navbar-heading">{item.title}</h6>}
          {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
        </div>
      );
    });
  }

  function getSubitems(ids: any, parentId: any, arr: any) {
    return ids.map(function (id: any) {
      const item = data[arr.splice(arr.indexOf(id), 1)];
      if (location.pathname.startsWith('/edit-profile')) {
        return null
      }
      if ((location.pathname.startsWith('/p') || location.pathname.startsWith('/create-publisher')) && (item.id.startsWith('A') || item.id.startsWith('C'))) {
        return null
      }
      if (location.pathname.startsWith('/a/') && (item.id.startsWith('P') || item.id.startsWith('C'))) {
        return null
      }
      if (location.pathname.startsWith('/admin') && (item.id.startsWith('P') || item.id.startsWith('A'))) {
        return null
      }
      if (location.pathname.startsWith('/p') && item.url.search("/xxxxx/") !== -1) {
        if (location.pathname.split("/")[2] && location.pathname.split("/")[2] !== 'xxxxx') {
          item.url = item.url.replace('xxxxx', location.pathname.split("/")[2])
        }
      }
      return (
        <Nav.Item key={id}>
          {item.children ? (
            <>
              <Nav.Link onClick={() => handleClick(id, parentId)} role="button">
                {item.icon && <FeatherIcon icon={item.icon} size="17" />}
                {item.title}
                <FeatherIcon icon="chevron-down" size="1em" className={`ms-auto nav-chevron ${isExpanded(id) && 'active'}`} {...props} />
              </Nav.Link>
              <Collapse in={isExpanded(id)}>
                <div>
                  <div className="nav nav-sm flex-column">{getSubitems(item.children, id, arr)}</div>
                </div>
              </Collapse>
            </>
          ) : (
            <Nav.Link as={Link} to={item.url} active={location.pathname === item.url} onClick={() => handleClick(id, parentId)}>
              {item.icon && <FeatherIcon icon={item.icon} size="17" />}
              {item.title}
            </Nav.Link>
          )}
        </Nav.Item>
      );
    });
  }

  function handleClick(itemId: any, parentId: any, setVisible: any = undefined) {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);

    if (setVisible) {
      setVisible(false);
    }
  }

  const toggler = <Navbar.Toggle />;

  const brand = (
    <Navbar.Brand as={Link} to="/">
      <img className="navbar-brand-img" src="/logo-ady-v2.png" height="30" style={{marginTop: 7, marginBottom: 7}} alt="..." />
    </Navbar.Brand>
  );
  const user = (
    <Dropdown align="end" className="d-md-none">
      <Dropdown.Toggle as={Avatar} size="sm" status="online" role="button">
        <FeatherIcon className="rounded-circle user-avatar" icon="user" size="40" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/dashboard-select">
          Switch Dashboard
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/edit-profile">
          Edit Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={()=>{
          storeCredentials("")
          window.location.assign('/sign-in')
        }}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const form = (
    <form className="mt-4 mb-3 d-md-none">
      <InputGroup className="input-group-merge input-group-reverse input-group-rounded">
        <Form.Control type="search" placeholder="Search" aria-label="Search" />
        <InputGroup.Text>
          <FeatherIcon icon="search" size="15" />
        </InputGroup.Text>
      </InputGroup>
    </form>
  );

  const footer = (
    <div className="navbar-user d-none d-md-flex">
      <Dropdown drop="up">
        <Dropdown.Toggle as={Avatar} size="sm" status="online" role="button">
          <FeatherIcon className="rounded-circle user-avatar" icon="user" size="40" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/dashboard-select">
            Switch Dashboard
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/edit-profile">
            Edit Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={()=>{
            storeCredentials("")
            window.location.assign('/sign-in')
          }}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {form}
      {getItems(Object.keys(data))}
      <div className="mt-auto mb-md-4" />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <>
      <Navbar expand="md" className="navbar-vertical fixed-start" collapseOnSelect={true} {...props}>
        <Container fluid>
          {toggler}
          {brand}
          {user}
          {collapse}
        </Container>
      </Navbar>
      <ModalSearch visible={modalSearchVisible} onDismiss={() => setModalSearchVisible(false)} />
      <ModalNotifications visible={modalNotificationsVisible} onDismiss={() => setModalNotificationsVisible(false)} />
    </>
  );
}
