import React, { Component } from 'react'
import { Card, Table, Dropdown, InputGroup, Form, Col, Container, Row, Button, Nav, Badge } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import Header from './components/Header'
// @ts-ignore
import FeatherIcon from 'feather-icons-react';

interface CampaignsListProps {
  navigate: any
  params: any
  location: any
}

type CampaignsListStates = {
  campaigns: any[],
  search: string
}

class CampaignsList extends Component <CampaignsListProps, CampaignsListStates> {
  constructor (props: CampaignsListProps) {
    super(props)
    this.state = {
      campaigns: [],
      search: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as CampaignsListStates)
  }

  searchChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as CampaignsListStates, ()=>this.loadCampaigns())
  }

  componentDidMount () {
    this.loadCampaigns()
  }

  componentDidUpdate(prevProps: Readonly<CampaignsListProps>, prevState: Readonly<CampaignsListStates>, snapshot?: any): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadCampaigns()
    }
  }

  loadCampaigns = () => {
    const { token } = getCredentials()
    let status = ''
    const pathArr = this.props.location.pathname.split("/")
    const path = pathArr[pathArr.length-1]
    if (path === 'stopped') {
      status = 'status=stopped&'
    }
    if (path === 'active') {
      status = 'status=active&'
    }
    if (path === 'completed') {
      status = 'status=completed&'
    }
    if (path === 'review') {
      status = 'status=review&'
    }
    let search = ''
    if (this.state.search !== '') {
      search = 'search='+this.state.search+'&'
    }
    fetch(
      config.app.apiUri + '/api/v1/campaign' + (status || search ? '?' : '') + status + search, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        this.setState({campaigns: json.campaigns})
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Overview</Header.Pretitle>
                        <Header.Title className="text-truncate">Campaigns</Header.Title>
                      </Col>
                      <Col xs="auto">
                        <Button onClick={()=>this.props.navigate('/a/create-campaign')}>Create Campaign</Button>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <Card>
                  <Card.Header>
                    <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                      <Form.Control type="search" placeholder="Search" name="search" value={this.state.search} onChange={this.searchChange}/>
                      <InputGroup.Text>
                        <FeatherIcon icon="search" size="1em" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Card.Header>
                  <Table size="sm" className="card-table table-nowrap" responsive>
                    <thead>
                      <tr>
                        <th>campaign</th>
                        <th>progress</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.campaigns.map((campaign: any)=>
                        <tr key={campaign.id} onClick={()=>this.props.navigate('/a/campaign/'+campaign.id)} style={{cursor: "pointer"}}>
                          <td>
                            {campaign.name}
                            <div className='small text-muted'>{campaign.description}</div>
                          </td>
                          <td>{campaign.spent / campaign.budget * 100} %</td>
                          <td>{campaign.status}</td>
                        </tr>
                      )}
                      {this.state.campaigns.length === 0 ?
                        <tr>
                          <td className="text-muted text-center p-5" colSpan={3}>No campaigns found</td>
                        </tr>
                      : null}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function CampaignsListWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  return <CampaignsList navigate={navigate} params={params} location={location}/>
}