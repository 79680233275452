import React, { Component } from 'react'
import { Dropdown, Col, Container, Row, Form, Alert, Button, Table, Modal } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from './components/Header'
import Flatpickr from './components/Flatpickr'

const usersex: any = {
  any: 'Any',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  maleother: 'Male and Other',
  femaleother: 'Female and Other'
}

const botsex: any = {
  any: 'Any',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  maleother: 'Male and Other',
  femaleother: 'Female and Other'
}

const adult: any = {
  any: 'Any',
  nonadult: 'Non adult',
  adultonly: 'Adult only',
}

interface CreateUpdateCampaignProps {
  navigate: any
  params: any
}

type CreateUpdateCampaignStates = {
  error: string
  name: string
  description: string
  content: string
  trigger: string
  audienceadulttype: string
  audiencesex: string
  audiencebotsex: string
  datefrom: string
  dateto: string
  budgetdailylimit: string
  budgetbidmax: string
  budget: string
  // loading
  isLoading: boolean
  // reply&response
  showModal: boolean
  responsereplies: any[]
  modalResponse: string
  modalReply: string
}

class CreateUpdateCampaign extends Component <CreateUpdateCampaignProps, CreateUpdateCampaignStates> {
  constructor (props: CreateUpdateCampaignProps) {
    super(props)
    this.state = {
      error: '',
      name: '',
      description: '',
      content: '',
      trigger: '',
      audienceadulttype: 'any',
      audiencesex: 'any',
      audiencebotsex: 'any',
      datefrom: '',
      dateto: '',
      budgetdailylimit: '',
      budgetbidmax: '',
      budget: '',
      // loading
      isLoading: false,
      // reply&response
      showModal: false,
      responsereplies: [],
      modalResponse: '',
      modalReply: ''
    }
  }


  componentDidMount () {
    this.loadCampaign()
  }

  loadCampaign = () => {
    if (!this.props.params.id) {
      return
    }
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaign/' + this.props.params.id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            name: json.campaign.name,
            description: json.campaign.description,
            content: json.campaign.content,
            trigger: json.campaign.trigger ? json.campaign.trigger : '',
            audienceadulttype: json.campaign.audienceadulttype,
            audiencesex: json.campaign.audiencesex,
            audiencebotsex: json.campaign.audiencebotsex,
            datefrom: json.campaign.datefrom,
            dateto: json.campaign.dateto,
            budgetdailylimit: json.campaign.budgetdailylimit === '2147483647' ? '' : (parseInt(json.campaign.budgetdailylimit) / 100).toString(),
            budgetbidmax: (parseInt(json.campaign.budgetbidmax) / 100).toString(),
            budget: (parseInt(json.campaign.budget) / 100).toString(),
            responsereplies: json.campaign.responses
          })
        }
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as CreateUpdateCampaignStates)
  }

  inputFromChange = (event: any) => {
    this.setState({ datefrom: event[0] })
  }

  inputToChange = (event: any) => {
    this.setState({ dateto: event[0] })
  }

  removeResponseReply = (index: number) => {
    const responseReplies = this.state.responsereplies
    responseReplies.splice(index, 1);
    this.setState({responseReplies} as any)
  }

  closeClearModal = () => {
    this.setState({
      showModal: false,
      modalReply: '',
      modalResponse: ''
    })
  }

  addToReponseReplies = () => {
    const responseReplies = this.state.responsereplies
    responseReplies.push({
      reply: this.state.modalReply,
      response: this.state.modalResponse
    })
    this.setState({responseReplies} as any)
    this.closeClearModal()
  }
  
  createUpdateCampaign = () => {
    this.setState({isLoading: true})
    const { token } = getCredentials()
    const update = this.props.params.id ? '/'+this.props.params.id : ''
    fetch(
      config.app.apiUri + '/api/v1/campaign' + update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.description,
          content: this.state.content,
          trigger: this.state.trigger,
          audienceadulttype: this.state.audienceadulttype,
          audiencesex: this.state.audiencesex,
          audiencebotsex: this.state.audiencebotsex,
          datefrom: this.state.datefrom ? this.state.datefrom.valueOf() : '',
          dateto: this.state.dateto ? this.state.dateto.valueOf() : new Date('9999-12-31').valueOf(),
          budgetdailylimit: this.state.budgetdailylimit !== "" ? Math.floor(parseFloat(this.state.budgetdailylimit)*100) : '2147483647',
          budgetbidmax: Math.floor(parseFloat(this.state.budgetbidmax)*100),
          budget: Math.floor(parseFloat(this.state.budget)*100),
          responsereplies: this.state.responsereplies
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          if (this.props.params.id) {
            this.props.navigate('/a/campaign/' + this.props.params.id)
          } else {
            this.props.navigate('/a/campaign/' + json.campaign.id + '/payment')
          }
        }
        if (json.status === 'error') {
          this.setState({ isLoading: false, error: json.message })
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12} lg={10} xl={8}>
                <Header className="mt-md-5">
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>new campaign</Header.Pretitle>
                        <Header.Title>{this.props.params.id ? "Update Campaign" : "Create a New Campaign"}</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <form className="mb-4">
                  <div className="form-group">
                    <Form.Label>Campaign Name</Form.Label>
                    <Form.Text className="text-muted">What you will remember it by.</Form.Text>
                    <Form.Control value={this.state.name} onChange={this.inputChange} name="name" type="text" />
                  </div>
                  <div className="form-group">
                    <Form.Label className="mb-1">Campaign Description</Form.Label>
                    <Form.Text className="text-muted">Describe your campaign, add information about it.</Form.Text>
                    <Form.Control value={this.state.description} onChange={this.inputChange} name="description" type="text" as="textarea" rows={3}/>
                  </div>
                  <h2 className="mt-6">Campaign Targeting</h2>
                  <hr className="mt-1 mb-4" />
                  <div className="form-group">
                    <Form.Label className="mb-1">Campaign Trigger</Form.Label>
                    <Form.Text className="text-muted">Defined campaign trigger. <br></br>Example: "buying energy drink"</Form.Text>
                    <Form.Control value={this.state.trigger} onChange={this.inputChange} name="trigger" type="text" as="textarea" rows={2}/>
                  </div>
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <Form.Label className="mb-1">User sex</Form.Label>
                        <Form.Text className="text-muted">Restrictions by users sex</Form.Text>
                        <Dropdown align="end">
                          <Dropdown.Toggle role="button" variant='outline-secondary' className="w-100">
                            <span>{usersex[this.state.audiencesex]}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            {Object.keys(usersex).map((key: string)=>{
                              return <Dropdown.Item onClick={()=>this.setState({audiencesex: key})} key={key}>{usersex[key]}</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <Form.Label className="mb-1">ChatBot sex</Form.Label>
                        <Form.Text className="text-muted">Restrictions by chatbot sex</Form.Text>
                        <Dropdown align="end">
                          <Dropdown.Toggle role="button" variant='outline-secondary' className="w-100">
                            <span>{botsex[this.state.audiencebotsex]}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            {Object.keys(botsex).map((key: string)=>{
                              return <Dropdown.Item onClick={()=>this.setState({audiencebotsex: key})} key={key}>{botsex[key]}</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <Form.Label className="mb-1">Adult apps</Form.Label>
                        <Form.Text className="text-muted">Show on adult focused platforms</Form.Text>
                        <Dropdown align="end">
                          <Dropdown.Toggle role="button" variant='outline-secondary' className="w-100">
                            <span>{adult[this.state.audienceadulttype]}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            {Object.keys(adult).map((key: string)=>{
                              return <Dropdown.Item onClick={()=>this.setState({audienceadulttype: key})} key={key}>{adult[key]}</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                  <h2 className="mt-6">Campaign Content / Responses</h2>
                  <hr className="mt-1 mb-4" />
                  <div className="form-group">
                    <Form.Label className="mb-1">Campaign Initial Content</Form.Label>
                    <Form.Text className="text-muted">Question, Phrase, Sentence (Whatever bot will say). <br></br>Example: "Have you tasted new GringoLino energy drink?"</Form.Text>
                    <Form.Control value={this.state.content} onChange={this.inputChange} name="content" type="text" as="textarea" rows={2}/>
                  </div>
                  <div className='pb-2'>Responses and Replys</div>
                  <div className="text-muted form-text pb-2">With following entries users responses to initial content will be logged and replied to.</div>
                  <Table size='sm' className="mb-3 border-bottom border-light">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Users Response</th>
                        <th>Bots Reply</th>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.responsereplies.map((responsereply, index) => {
                        return <tr key={index}>
                          <td>{index+1}.</td>
                          <td>{responsereply.response}</td>
                          <td>{responsereply.reply}</td>
                          <td>{this.props.params.id === undefined ? <Button size='sm' variant='danger' className="text-white pt-0 pb-0 pr-1 pl-1" onClick={()=>this.removeResponseReply(index)}>x</Button> : null}</td>
                        </tr>
                      })}
                      {this.state.responsereplies.length === 0 ?
                        <tr>
                          <td className="text-muted text-center" colSpan={4}>No entries yet</td>
                        </tr>
                      : null}
                    </tbody>
                  </Table>
                  <div>
                    <Button size='sm' variant='secondary' onClick={()=>this.setState({showModal: true})}>Add Response & Reply</Button>
                  </div>
                  <Modal show={this.state.showModal} onHide={()=>this.closeClearModal()}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Response & Reply</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className="mb-1">Users response</Form.Label>
                          <Form.Text className="text-muted">Aproximiation what user would reply. Example: "Have not tasted it yet!"</Form.Text>
                          <Form.Control value={this.state.modalResponse} onChange={this.inputChange} name="modalResponse" type="text"/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className="mb-1">Bot reply</Form.Label>
                          <Form.Text className="text-muted">What bot replies to users response. Example: "You should! I've heard it's really tasty!"</Form.Text>
                          <Form.Control value={this.state.modalReply} onChange={this.inputChange} name="modalReply" type="text"/>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={()=>this.closeClearModal()}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={()=>this.addToReponseReplies()}>
                        Add Response & Reply
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <h2 className="mt-6">Campaign Timeline</h2>
                  <hr className="mt-1 mb-4" />
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <Form.Label>Start date</Form.Label>
                        <Form.Text className="text-muted">Pick date at lease few days in advance to allow for review</Form.Text>
                        <Form.Control as={Flatpickr} value={this.state.datefrom} onChange={this.inputFromChange} name="datefrom" className="text-muted" options={{ dateFormat: 'M j Y' }} type="text" />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <Form.Label>End date</Form.Label>
                        <Form.Text className="text-muted">Leave it empty if you want to run it until budget runs out</Form.Text>
                        <Form.Control as={Flatpickr} value={this.state.dateto} onChange={this.inputToChange} name="dateto" className="text-muted" options={{ dateFormat: 'M j Y' }} type="text" />
                      </div>
                    </Col>
                  </Row>
                  <h2 className="mt-6">Campaign Budgets</h2>
                  <hr className="mt-1 mb-4" />
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="form-group"> 
                        <Form.Label>Campaign budget</Form.Label>
                        <Form.Text className="text-muted">Campaign full budget, cannot be increased later.</Form.Text>
                        <Form.Control disabled={this.props.params.id} value={this.state.budget} onChange={this.inputChange} name="budget" type="text" />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <Form.Label>Campaign bid max</Form.Label>
                        <Form.Text className="text-muted">What is maximum amount you are prepared to pay for single camapaign engagement</Form.Text>
                        <Form.Control value={this.state.budgetbidmax} onChange={this.inputChange} name="budgetbidmax" type="text" />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <Form.Label>Budget daily limit</Form.Label>
                        <Form.Text className="text-muted">Campaign daily limit. Leave it empty to run campaign without a budget limit</Form.Text>
                        <Form.Control value={this.state.budgetdailylimit} onChange={this.inputChange} name="budgetdailylimit" type="text" />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-5 mb-5" />
                  { this.state.error !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({error: ''})} dismissible>
                      {this.state.error}
                    </Alert>
                  :null}
                  { this.state.isLoading === false ?
                    <Button className="w-100" onClick={()=>this.createUpdateCampaign()}>{this.props.params.id ? "Update campaign" : "Create campaign"}</Button>
                  : 
                    <Button className="w-100" disabled>Saving...</Button>
                  }
                  <Button variant="link" className="w-100 text-muted" onClick={()=>this.props.navigate('/a/')}>
                    {this.props.params.id ? "Cancel update" : "Cancel creation"}
                  </Button>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function CreateUpdateCampaignWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <CreateUpdateCampaign navigate={navigate} params={params}/>
}