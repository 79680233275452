export default {
  Acreate: {
    children: [
      'Acreatecampaign'
    ],
    title: 'create',
    id: 'Acreate'
  },
  Acreatecampaign: {
    icon: 'plus',
    id: 'Acreatecampaign',
    title: 'New Campaign',
    url: '/a/create-campaign',
  },
  Acampaigns: {
    children: [
      'Aallcampaigns',
      'Aactivecampaigns',
      'Astoppedcampaigns',
      'Acompletedcampaigns',
      'Areviewcampaigns'
    ],
    id: 'Acampaigns',
    title: 'campaigns'
  },
  Aactivecampaigns: {
    icon: 'play',
    id: 'Aactivecampaigns',
    title: 'Active',
    url: '/a/active'
  },
  Astoppedcampaigns: {
    icon: 'pause',
    id: 'Astoppedcampaigns',
    title: 'Stopped',
    url: '/a/stopped'
  },
  Acompletedcampaigns: {
    icon: 'check',
    id: 'Acompletedcampaigns',
    title: 'Completed',
    url: '/a/completed'
  },
  Areviewcampaigns: {
    icon: 'eye',
    id: 'Areviewcampaigns',
    title: 'Review',
    url: '/a/review'
  },
  Aallcampaigns: {
    icon: 'list',
    id: 'Aallcampaigns',
    title: 'All',
    url: '/a/'
  },
  Ppublishermain: {
    children: [
      'Ppublisher',
      'Pwithdraw',
      'Pedit'
    ],
    id: 'Ppublishermain',
    title: 'publisher',
  },
  Ppublisher: {
    icon: 'bar-chart-2',
    id: 'Ppublisher',
    title: 'Dashboard',
    url: '/p',
  },
  Pwithdraw: {
    icon: 'dollar-sign',
    id: 'Pwithdraw',
    title: 'Withdraw',
    url: '/p/xxxxx/withdrawals',
  },
  Pedit: {
    icon: 'settings',
    id: 'Pedit',
    title: 'Settings',
    url: '/p/xxxxx/edit',
  },
  Poptions: {
    children: [
      'Pintprod',
      'Pinttest'
    ],
    id: 'Ppublisher',
    title: 'Integration',
  },
  Pintprod: {
    icon: 'truck',
    id: 'Pintprod',
    title: 'Production',
    url: '/p/xxxxx/integration',
  },
  Pinttest: {
    icon: 'code',
    id: 'Pinttest',
    title: 'Test env.',
    url: '/p/xxxxx/testintegration',
  },
  Cadmins: {
    children: [
      'Cadmincampaigns',
      'Cadminpublishers',
      'Cadminverfications',
      'Cadminwithdrawals'
    ],
    id: 'Cadmins',
    title: 'Admin',
  },
  Cadmincampaigns: {
    icon: 'code',
    id: 'Cadmincampaigns',
    title: 'Campaigns',
    url: '/admin/campaigns',
  },
  Cadminpublishers: {
    icon: 'code',
    id: 'Cadminpublishers',
    title: 'Publishers',
    url: '/admin/publishers',
  },
  Cadminverfications: {
    icon: 'code',
    id: 'Cadminverfications',
    title: 'Verifications',
    url: '/admin/verifications',
  },
  Cadminwithdrawals: {
    icon: 'code',
    id: 'Cadminwithdrawals',
    title: 'Withdrawals',
    url: '/admin/withdrawals',
  }
} as any;
