import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap'
import { config } from './config'
import { Link, useNavigate } from 'react-router-dom';
import { getCredentials } from './utils'

interface LoaderProps {
  navigate: any
}

type LoaderStates = {
}

class Loader extends Component <LoaderProps, LoaderStates> {
  constructor (props: LoaderProps) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    this.loadUser()
  }

  loadUser = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          if (json.data.dashboard === 'advertiser'){
            this.props.navigate('/a/')
          }
          if (json.data.dashboard === 'publisher'){
            this.props.navigate('/p')
          }
          if (json.data.dashboard === null){
            this.props.navigate('/dashboard-select')
          }
        }
        if (json.status === 'nouser') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center align-middle vw-100 vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
}

export default function LoaderWithNavigation() {
  const navigate = useNavigate()
  return <Loader navigate={navigate}/>
}