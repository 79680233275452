import React from 'react';
import Sidenav from './components/Sidenav';

function Dash(props: any) {
  return (
    <div>
      <Sidenav/>
      {props.children}
    </div>
  );
}

export default Dash;
