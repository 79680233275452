import React, { Component } from 'react';
import { Col, Container, Row, Card, Dropdown, InputGroup, Form, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import { getCredentials } from './utils'
import Dash from './Dash'
import Header from './components/Header'

interface AdminVerificationsViewProps {
  navigate: any
}

type AdminVerificationsViewStates = {
  search: string
  publishers: any[]
}

class AdminVerificationsView extends Component <AdminVerificationsViewProps, AdminVerificationsViewStates> {
  constructor (props: AdminVerificationsViewProps) {
    super(props)
    this.state = {
      search: '',
      publishers: []
    }
  }

  componentDidMount () {
    this.loadVerifications()
  }

  loadVerifications = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/verifications', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({
            publishers: json.publishers
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  searchChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AdminVerificationsViewStates, ()=>this.loadVerifications())
  }

  verified = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/publishers/' + id + '/verified', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadVerifications()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  unverified = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/publishers/' + id + '/unverified', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadVerifications()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    const { token } = getCredentials()
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Admin</Header.Pretitle>
                        <Header.Title className="text-truncate">Verifications</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <Card>
                  <Card.Header>
                    <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                      <Form.Control type="search" placeholder="Search" name="search" value={this.state.search} onChange={this.searchChange}/>
                      <InputGroup.Text>
                        <FeatherIcon icon="search" size="1em" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Card.Header>
                  <Table size="sm" className="card-table table-nowrap">
                    <thead>
                      <tr>
                        <th>publisher</th>
                        <th>status</th>
                        <th>documents</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.publishers.map((publisher: any)=>
                        <tr key={publisher.id}>
                          <td /*onClick={()=>this.props.navigate('/a/campaign/'+publisher.id)} style={{cursor: "pointer"}}*/>
                            {publisher.name} (<Link to={'mailto:'+publisher.user.email}>{publisher.user.email}</Link>)
                            <div className='small text-muted'>{publisher.description}</div>
                          </td>
                          <td>{publisher.status}</td>
                          <td>
                            {publisher.verifications.map((verification: any) => {
                              return <div>
                                {verification.type === 'Text item' ? <div><div>{verification.title}:</div><div>{verification.data}</div></div> : null}
                                {verification.type === 'File item' ? <div><div>{verification.title}:</div><div><a href={config.app.apiUri + '/api/v1/admin/file/' +  verification.data + '?key=' + token} target='blank'>Open document</a></div></div> : null}
                                {verification.type === 'Image item' ? <div><div>{verification.title}:</div><div><a href={config.app.apiUri + '/api/v1/admin/file/' + verification.data + '?key=' + token} target='blank'><img height={50} width={50} src={config.app.apiUri + '/api/v1/admin/file/' + verification.data + '?key=' + token} alt="" /></a></div></div> : null}
                              </div>
                            })}
                          </td>
                          <td>
                            <Dropdown >
                              <Dropdown.Toggle variant="light">Action</Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item disabled={!(publisher.status === 'in review')} onClick={()=>this.verified(publisher.id)}>Verified</Dropdown.Item>
                                <Dropdown.Item disabled={!(publisher.status === 'in review')} onClick={()=>this.unverified(publisher.id)}>Unverified</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function AdminVerificationsViewWithNavigation() {
  const navigate = useNavigate()
  return <AdminVerificationsView navigate={navigate}/>
}