import React, { Component } from 'react'
import { Card, Row, Col, Container, Button, ListGroup, Dropdown, Modal, Form, Alert } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import FeatherIcon from 'feather-icons-react'
import Header from './components/Header'
import Moment from 'react-moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface DemoIntegrationViewProps {
  navigate: any
  params: any
}

type DemoIntegrationViewStates = {
  publisher: any
  publishers: any[]
  displayType: string
}

class DemoIntegrationView extends Component <DemoIntegrationViewProps, DemoIntegrationViewStates> {
  constructor (props: DemoIntegrationViewProps) {
    super(props)
    this.state = {
      publisher: undefined,
      publishers: [],
      displayType: 'raw'
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as DemoIntegrationViewStates)
  }

  componentDidMount () {
    this.loadPublishers()
  }

  loadPublishers = (load: boolean = true) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publishers.length > 0) {
            if (this.props.params.id === 'xxxxx') {
              this.props.navigate('/p/' + json.publishers[0].id + '/testintegration')
            } else {
              this.setState({
                publishers: json.publishers
              }, () => {
                if (load) this.loadTokens()
              })
            }
          } else {
            this.props.navigate('/create-publisher')
          }
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  componentDidUpdate(prevProps: Readonly<DemoIntegrationViewProps>, prevState: Readonly<DemoIntegrationViewStates>, snapshot?: any): void {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadTokens()
      this.loadPublishers(false)
    }
  }

  loadTokens = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/demointegration', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({
            publisher: json.publisher
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  removeDemoToken = async (tokenId: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/integration/removedemotoken/' + tokenId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.loadTokens()
        }
      })
  }

  newDemoToken = async () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id + '/integration/newdemotoken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.loadTokens()
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Header>
            <Container fluid>
              <Header.Body>
                <Row className="align-items-end">
                  <Col>
                    <Header.Pretitle as="h6">Publisher test integration</Header.Pretitle>
                    <div className='d-flex flex-row'>
                      <Header.Title as="h1">
                        {this.state.publisher?.name}
                      </Header.Title>
                      <Dropdown>
                        <Dropdown.Toggle role="menu" variant='link' style={{paddingTop: 3, paddingLeft: 5}}></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.publishers.map((publisher: any)=>{
                            return <Dropdown.Item as={Link} to={'/p/'+publisher.id+'/testintegration'} key={publisher.id}>{publisher.name}</Dropdown.Item>
                          })}
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item as={Link} to="/create-publisher" key="newpublisher">New Publisher</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </Header.Body>
            </Container>
          </Header>
          <Container fluid>
            <Row>
              <Col className='pt-3 pb-3'>
                <h1>Test Integration</h1>
                <h4><a href={'/p/'+this.state.publisher?.id+'/testintegration#Preparation'}>0. Preparation</a></h4>
                <h4><a href={'/p/'+this.state.publisher?.id+'/testintegration#ChatEntry'}>1. Chat Entry</a></h4>
                <h4><a href={'/p/'+this.state.publisher?.id+'/testintegration#PromptRetrieve'}>2. Content Retrieve</a></h4>
                <h4><a href={'/p/'+this.state.publisher?.id+'/testintegration#ContentRetrieveWithChat'}>X. Chat & Content</a></h4>
              </Col>
            </Row>
            <Row id="Preparation">
              <Col className='pt-5 pb-3'>
                <h2>0. Preparation</h2>
                <div className='pb-3'>All request to API endpoint require authorization key which can be ganerated below.</div>
                <div className='fade alert alert-light show'>
                  <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'raw' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'raw'})}>Raw Request</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'nodejs' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'nodejs'})}>Nodejs</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'python' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'python'})}>Python</div>
                    </li>
                  </ul>
                  { this.state.displayType === 'raw' ?
                    <>
                      <div>Base URL: <span><code>{config.app.apiUri}/api/v1/</code></span></div>
                      <div>Header: <span><code>Authorization: {"<your test key>"}</code></span></div>
                    </>
                  : null}
                  { this.state.displayType === 'nodejs' ?
                    <>
                      <div>Install npm package:</div>
                      <div className='pb-2'><code>npm install --save chatady-node</code></div>
                      <div>Require ChatADy package:</div>
                      <div className='pb-2'><code>{`const ChatADy = require('chatady-node');`}</code></div>
                      <div>Or Import it using ES:</div>
                      <div className='pb-2'><code>{`import ChatADy from 'chatady-node';`}</code></div>
                      <div>Initialize it using your info (your publisher is: <code>"{this.state.publisher?.id}"</code>, key can be generated below):</div>
                      <div><code>{`const client = ChatADy('your_publisher_id', 'your_api_key', {environment: 'testing'});`}</code></div>
                    </>
                  : null}
                  { this.state.displayType === 'python' ?
                    <>
                      <div>Install python package:</div>
                      <div className='pb-2'><code>pip install chatady</code></div>
                      <div>Import and initialize the client:</div>
                      <div><code>from chatady.chatady import ChatADy</code></div>
                      <div><code>{`client = ChatADy('your_publisher_id', 'your_api_key', {'environment': 'testing'})`}</code></div>
                    </>
                  : null}
                </div>
                <Card className='mt-3'>
                  <Card.Header>
                    <h4 className="card-header-title">Test Keys</h4>
                    <Button onClick={()=>this.newDemoToken()}>New Test Key</Button>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-lg list-group-flush my-n4">
                      {this.state.publisher && this.state.publisher.demokeys.map((key: any, i: number) => {
                        return (
                          <ListGroup.Item key={key.id}>
                            <Row className="align-items-center">
                              <Col className="ms-n2 d-flex flex-row">
                                <div className="mb-1" style={{userSelect: 'all', overflow: 'scroll', whiteSpace: 'nowrap'}}>
                                  {key.id}
                                </div>
                                <div>
                                  <CopyToClipboard text={key.id}>
                                    <FeatherIcon icon="clipboard" size="18" style={{cursor: 'pointer', paddingBottom: 5}}/>
                                  </CopyToClipboard>
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-1">
                                  <Moment format="DD/MM/YYYY">{key.createdAt}</Moment>
                                </div>
                              </Col>
                              <Col xs="auto">
                                <Dropdown align="end">
                                  <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                                    <FeatherIcon icon="more-vertical" size="17" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={()=>this.removeDemoToken(key.id)}>Remove</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                      {this.state.publisher && this.state.publisher.demokeys.length === 0 ?
                        <ListGroup.Item className="pb-5 pt-5 text-center">No test keys yet</ListGroup.Item>
                      : null}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row id="ChatEntry">
              <Col className='pt-5 pb-3'>
                <h2>1. Chat Entry</h2>
                <div className='pb-3'>With chat entry endpoint we receive conversations between chatbots and humans. With it we can detect when content is used and validate if conversation is genuine.</div>
                <div className='fade alert alert-light show'>
                  <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'raw' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'raw'})}>Raw Request</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'nodejs' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'nodejs'})}>Nodejs</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'python' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'python'})}>Python</div>
                    </li>
                  </ul>
                  { this.state.displayType === 'raw' ?
                    <>
                      <div>URL: <span><code>{`/test-chats/<PublisherID>/<ChatID>`}</code></span></div>
                      <div>Type: <span><code>POST</code></span></div>
                      <div>URL Parameters:</div>
                      <div className='ps-3'>Publisher ID: <span><code>{this.state.publisher && this.state.publisher.id ? this.state.publisher.id : null}</code></span></div>
                      <div className='ps-3'>Chat ID: <span><code>{`<unique ID identifying chat between bot and human of string type>`}</code></span></div>
                      <div>Body:</div>
                      <div className='ps-3'>entry: <span><code>{`<text entry of conversation between bot and human of string type>`}</code></span></div>
                      <div className='ps-3'>human: <span><code>{`<boolean value / true if speaker is human / false if speaker is bot>`}</code></span></div>
                      <div>Success Response: <span><code>{`{"status": "success"}`}</code></span></div>
                      <div>Error Response: <span><code>{`{"status": "error", "message": "<error info>"}`}</code></span></div>
                    </>
                  : null}
                  { this.state.displayType === 'nodejs' ?
                    <>
                      <div>Create new chat entry:</div>
                      <div className='pb-2'><code>await client.newChat('unique_id_identifying_conversation', 'your_entry_message', 'boolean_human_or_bot')</code></div>
                    </>
                  : null}
                  { this.state.displayType === 'python' ?
                    <>
                      <div>Create new chat entry:</div>
                      <div className='pb-2'><code>client.new_chat(chat_id='unique_id_identifying_conversation', entry='your_entry_message', human='boolean_human_or_bot')</code></div>
                    </>
                  : null}
                </div>
                <Card className="mt-4">
                  <Card.Header>
                    <h4 className="card-header-title">Last 5 Test Chats</h4>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup className="list-group-lg list-group-flush my-n4">
                      {this.state.publisher && this.state.publisher.demochats.map((chat: any, i: number) => {
                        return (
                          <ListGroup.Item key={chat.id}>
                            <Row className="align-items-center">
                              <Col className="ms-n2">
                                {chat.humanid}
                              </Col>
                              <Col className="ms-n2">
                                {chat.human === true ? 'Human' : 'Bot'}
                              </Col>
                              <Col className="ms-n2">
                                {chat.entry}
                              </Col>
                              <Col xs="auto">
                                <Moment format="DD/MM/YYYY">{chat.createdAt}</Moment>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                      {this.state.publisher && this.state.publisher.demochats.length === 0 ?
                        <ListGroup.Item className="pb-5 pt-5 text-center">Last 5 test chats sent to test endpoint will be shown here</ListGroup.Item>
                      : null}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row id="PromptRetrieve">
              <Col className='pt-5 pb-3'>
                <h2>2. Content Retrieve</h2>
                <div className='pb-3'>With content endpoint we provide text that can be used for bot and sent to human as part of conversation.</div>
                <div className='fade alert alert-light show'>
                  <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'raw' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'raw'})}>Raw Request</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'nodejs' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'nodejs'})}>Nodejs</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'python' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'python'})}>Python</div>
                    </li>
                  </ul>
                  { this.state.displayType === 'raw' ?
                    <>
                      <div>URL: <span><code>{`/test-contents/<PublisherID>/<ChatID>`}</code></span></div>
                      <div>Type: <span><code>GET</code></span></div>
                      <div>URL Parameters:</div>
                      <div className='ps-3'>Publisher ID: <span><code>{this.state.publisher && this.state.publisher.id ? this.state.publisher.id : null}</code></span></div>
                      <div className='ps-3'>Chat ID: <span><code>{`<unique ID identifying chat between bot and human of string type>`}</code></span></div>
                      <div>URL Query Params:</div>
                      <div className='ps-3'>humansex: <span><code>{`<optional: Sex of human. String type can be one of following: "male", "female" or "other">`}</code></span></div>
                      <div className='ps-3'>botsex: <span><code>{`<optional: Sex of bot. String type can be one of following: "male", "female" or "other">`}</code></span></div>
                      <div>Success Response: <span><code>{`{"status": "success", "content": "<ad text content>"}`}</code></span></div>
                      <div>Success Response No AD Found: <span><code>{`{"status": "success", "content": ""}`}</code></span></div>
                      <div>Error Response: <span><code>{`{"status": "error", "message": "<error info>"}`}</code></span></div>
                    </>
                  : null}
                  { this.state.displayType === 'nodejs' ?
                    <>
                      <div>Retrieve Ad Content (humansex and botsex is optional):</div>
                      <div className='pb-2'><code>{`const response = await client.getContents('unique_id_identifying_conversation', { humansex: 'male', botsex: 'female' })`}</code></div>
                      <div>Response With Ad Content:</div>
                      <div className='pb-2'><code>{`
                        {"status": "success", "content": "<ad text content>", "id": "<id of content>", "earnings": <earnings in cents>}
                      `}</code></div>
                      <div>Response With No Ad Content:</div>
                      <div className='pb-2'><code>{`
                        {"status": "success", "content": "", "id": "", "earnings": 0}
                      `}</code></div>
                    </>
                  : null}
                  { this.state.displayType === 'python' ?
                    <>
                      <div>Retrieve Ad Content:</div>
                      <div className='pb-2'><code>{`response = client.get_contents('unique_id_identifying_conversation', { humansex: 'male', botsex: 'female' })`}</code></div>
                      <div>Response With Ad Content:</div>
                      <div className='pb-2'><code>{`
                        {"status": "success", "content": "<ad text content>", "id": "<id of content>", "earnings": <earnings in cents>}
                      `}</code></div>
                      <div>Response With No Ad Content:</div>
                      <div className='pb-2'><code>{`
                        {"status": "success", "content": "", "id": "", "earnings": 0}
                      `}</code></div>
                    </>
                  : null}
                </div>
                <div className='pb-5'></div>
              </Col>
            </Row>
            <Row id="ContentRetrieveWithChat">
              <Col className='pt-5 pb-3'>
                <h2>X. Chat & Content Retrieve</h2>
                <div className='pb-3'>Chat entry endpoint can be used to send in chat entry and receive content in one request.</div>
                <div className='fade alert alert-light show'>
                  <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'raw' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'raw'})}>Raw Request</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'nodejs' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'nodejs'})}>Nodejs</div>
                    </li>
                    <li className="nav-item">
                      <div className={"nav-link pb-3 pt-2 "+(this.state.displayType === 'python' ? 'active' : '')} role="button" onClick={()=>this.setState({displayType: 'python'})}>Python</div>
                    </li>
                  </ul>
                  { this.state.displayType === 'raw' ?
                    <>
                      <div>URL: <span><code>{`/test-chats/<PublisherID>/<ChatID>`}</code></span></div>
                      <div>Type: <span><code>POST</code></span></div>
                      <div>URL Parameters:</div>
                      <div className='ps-3'>Publisher ID: <span><code>{this.state.publisher && this.state.publisher.id ? this.state.publisher.id : null}</code></span></div>
                      <div className='ps-3'>Chat ID: <span><code>{`<unique ID identifying chat between bot and human of string type>`}</code></span></div>
                      <div>Body:</div>
                      <div className='ps-3'>entry: <span><code>{`<text entry of conversation between bot and human of string type>`}</code></span></div>
                      <div className='ps-3'>human: <span><code>{`<boolean value / true if speaker is human / false if speaker is bot>`}</code></span></div>
                      <div className='ps-3'>content: <span><code>{`<optional json object that triggers content retrieval>`}</code></span></div>
                      <div className='ps-3'>content.retrieve: <span><code>{`<boolean value / true if we want to retrieve content>`}</code></span></div>
                      <div className='ps-3'>content.humansex: <span><code>{`<optional: Sex of human. String type can be one of following: "male", "female" or "other">`}</code></span></div>
                      <div className='ps-3'>content.botsex: <span><code>{`<optional: Sex of bot. String type can be one of following: "male", "female" or "other">`}</code></span></div>
                      <div>Success Response: <span><code>{`{"status": "success"}`}</code></span></div>
                      <div>Error Response: <span><code>{`{"status": "error", "message": "<error info>"}`}</code></span></div>
                    </>
                  : null}
                  { this.state.displayType === 'nodejs' ?
                    <>
                      <div>Create new chat entry:</div>
                      <div className='pb-2'><code>{`await client.newChat('unique_id_identifying_conversation', 'your_entry_message', 'boolean_human_or_bot', { retrieve: true, humansex: 'male', botsex: 'female' })`}</code></div>
                    </>
                  : null}
                  { this.state.displayType === 'python' ?
                    <>
                      <div>Create new chat entry:</div>
                      <div className='pb-2'><code>{`client.new_chat(chat_id='unique_id_identifying_conversation', entry='your_entry_message', human='boolean_human_or_bot', { retrieve: True, humansex: 'male', botsex: 'female' })`}</code></div>
                    </>
                  : null}
                </div>
                <div className='pb-5'></div>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function DemoIntegrationViewWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <DemoIntegrationView navigate={navigate} params={params}/>
}