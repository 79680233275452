import React, { Component } from 'react'
import { Card, Table, Dropdown, InputGroup, Form, Col, Container, Row, Button, Nav, Badge, ProgressBar } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import Moment from 'react-moment'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from './components/Header'
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart, ChartLegend } from './components/Chart';

interface CampaignViewProps {
  navigate: any
  params: any
}

type CampaignViewStates = {
  campaign: any
  reach: any[]
  responses: any[]
}

const channeloptions = {
  plugins: {
    tooltip: {
      callbacks: {
        title: () => {
          return '';
        },
        afterLabel: () => {
          return '%';
        },
      },
    },
  },
};

const graphoptions = {
  scales: {
    y: {
      ticks: {},
    },
  },
};

class CampaignView extends Component <CampaignViewProps, CampaignViewStates> {
  constructor (props: CampaignViewProps) {
    super(props)
    this.state = {
      campaign: undefined,
      reach: [],
      responses:  []
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as CampaignViewStates)
  }

  componentDidMount () {
    this.loadDashboard()
    this.loadReach()
    this.loadResponses()
  }

  loadDashboard = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaign/'+this.props.params.id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        this.setState({campaign: json.campaign})
      })
  }

  loadReach = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaign/'+this.props.params.id+'/reach', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        this.setState({reach: json.reach})
      })
  }

  loadResponses = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaign/'+this.props.params.id+'/responses', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        this.setState({responses: json.responses})
      })
  }

  stop = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campaigns/' + id + '/stop', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadDashboard()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  resume = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/campsigns/' + id + '/resume', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadDashboard()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    const channellabels = this.state.responses.length > 0 ? this.state.responses.map((el)=>el.response) : [];
    const channeldatasets = [
      {
        data: this.state.responses.length > 0 ? this.state.responses.map((el)=>el.precentage) : [],
        backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC', '#1A5276', '#FF6B6B', '#3D9970', '#FFC300', '#EAECEE', '#8E44AD', '#17A589'],
      }
    ]
    const graphlabels: any[] = this.state.reach.length > 0 ? this.state.reach.map((el)=>el.date) : [];
    const graphdatasets: any[] = [
      {
        data: this.state.reach.length > 0 ? this.state.reach.map((el)=>el.count) : [],
      }
    ]
    // calculate graphs from data
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Campaign</Header.Pretitle>
                        <Header.Title className="text-truncate">{this.state.campaign?.name}</Header.Title>
                        <div className='small text-muted pt-2'>{this.state.campaign?.description}</div>
                      </Col>
                      <Col xs="auto">
                        <Dropdown>
                          <Dropdown.Toggle variant="primary">Campaign actions</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item disabled={!(this.state.campaign?.status === 'active')} onClick={()=>this.stop(this.state.campaign?.id)}>Stop</Dropdown.Item>
                            <Dropdown.Item disabled={!(this.state.campaign?.status === 'stopped')} onClick={()=>this.resume(this.state.campaign?.id)}>Resume</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            {this.state.campaign?.status === 'payment pending' ? <Dropdown.Item as={Link} to={"/a/campaign/"+this.props.params.id+"/payment"}>Payment</Dropdown.Item>:null}
                            <Dropdown.Item as={Link} to={"/a/campaign/"+this.props.params.id+"/edit"}>Edit</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">status</h6>
                        <span className="h2 mb-0">{this.state.campaign?.status}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="briefcase" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">start</h6>
                        <span className="h2 mb-0">
                          <Moment format="DD/MM/YYYY">{this.state.campaign?.datefrom}</Moment>
                        </span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="calendar" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">end</h6>
                        <span className="h2 mb-0">
                          { this.state.campaign?.dateto !== "31/12/9999" ? <Moment format="DD/MM/YYYY">{this.state.campaign?.dateto}</Moment> : '-' }
                        </span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="calendar" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">daily limit / total budget</h6>
                        <span className="h2 mb-0">{this.state.campaign?.budgetdailylimit !== 2147483647 ? '$'+(this.state.campaign?.budgetdailylimit/100) : 'No limit'} / ${this.state.campaign?.budget/100}</span>
                      </Col>
                      <Col xs="auto">
                      <FeatherIcon icon="dollar-sign" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} xl={8}>
                <Card>
                  <Card.Header>
                    <h4 className="card-header-title">Reach</h4>
                  </Card.Header>
                  <Card.Body>
                    {graphdatasets[0].data.length === 0 ?
                      <div className="text-muted d-flex justify-content-center align-items-center" style={{height: 300}}>No data yet</div>
                    :
                      <Chart>
                        <Bar data={{ labels: graphlabels, datasets: graphdatasets }} options={graphoptions} />
                      </Chart>
                    }
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} xl={4}>
              <Card>
                <Card.Header>
                  <h4 className="card-header-title">Responses</h4>
                </Card.Header>
                <Card.Body>
                  {channeldatasets[0].data.length === 0 ?
                    <div className="text-muted d-flex justify-content-center align-items-center" style={{height: 300}}>No data yet</div>
                  :
                    <>
                      <Chart layout="appended">
                        <Doughnut data={{ labels: channellabels, datasets: channeldatasets }} options={channeloptions} />
                      </Chart>
                      <ChartLegend data={{ labels: channellabels, datasets: channeldatasets }} />
                    </>
                  }
                </Card.Body>
              </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">Progress</h6>
                        <Row className="align-items-center g-0">
                          <Col xs="auto">
                            <span className="h2 me-2 mb-0">{this.state.campaign?.spent / this.state.campaign?.budget * 100}%</span>
                          </Col>
                          <Col>
                            <ProgressBar className="progress-sm" now={this.state.campaign?.spent / this.state.campaign?.budget * 100} />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="briefcase" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">Reached</h6>
                        <span className="h2 mb-0">{this.state.campaign?.count}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="clipboard" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">Spent today</h6>
                        <span className="h2 mb-0">${this.state.campaign?.spenttoday/100}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="dollar-sign" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} xl>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2">Budget spent</h6>
                        <span className="h2 mb-0">${this.state.campaign?.spent/100}</span>
                      </Col>
                      <Col xs="auto">
                        <FeatherIcon icon="dollar-sign" size="20" className="text-muted" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function CampaignViewWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <CampaignView navigate={navigate} params={params}/>
}