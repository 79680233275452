import React, { Component } from 'react';
import { Card, Table, Dropdown, InputGroup, Form, Col, Container, Row, Button, Nav, Badge } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import Header from './components/Header'
import Dash from './Dash'
import { getCredentials } from './utils'

interface DashboardselectProps {
  navigate: any
}

type DashboardselectStates = {
  search: string
  publishers: any[]
}

class Dashboardselect extends Component <DashboardselectProps, DashboardselectStates> {
  constructor (props: DashboardselectProps) {
    super(props)
    this.state = {
      search: '',
      publishers: []
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as DashboardselectStates)
  }

  searchChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as DashboardselectStates, ()=>this.loadPublishers())
  }

  componentDidMount () {
    this.loadPublishers()
  }

  loadPublishers = () => {
    const { token } = getCredentials()
    let search = '?status=review'
    if (this.state.search !== '') {
      search = '?search='+this.state.search
    }
    fetch(
      config.app.apiUri + '/api/v1/admin/publishers' + search, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({publishers: json.publishers})
        }
      })
  }

  approve = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/publishers/' + id + '/approve', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadPublishers()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Admin</Header.Pretitle>
                        <Header.Title className="text-truncate">Publishers</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <Card>
                  <Card.Header>
                    <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                      <Form.Control type="search" placeholder="Search" name="search" value={this.state.search} onChange={this.searchChange}/>
                      <InputGroup.Text>
                        <FeatherIcon icon="search" size="1em" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Card.Header>
                  <Table size="sm" className="card-table table-nowrap">
                    <thead>
                      <tr>
                        <th>publisher</th>
                        <th>status</th>
                        <th>chats</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.publishers.map((publisher: any)=>
                        <tr key={publisher.id}>
                          <td /*onClick={()=>this.props.navigate('/a/campaign/'+publisher.id)} style={{cursor: "pointer"}}*/>
                            {publisher.name} (<Link to={'mailto:'+publisher.user.email}>{publisher.user.email}</Link>)
                            <div className='small text-muted'>{publisher.description}</div>
                          </td>
                          <td>{publisher.status}</td>
                          <td>
                            {publisher.chats.map((chat: any) => {
                              return <div>{chat.humanid}: {chat.entry}</div>
                            })}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle variant="light">Action</Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item disabled={!(publisher.status === 'in review' || publisher.status === 'edit review')} onClick={()=>this.approve(publisher.id)}>Approve</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function DashboardselectWithNavigation() {
  const navigate = useNavigate()
  return <Dashboardselect navigate={navigate}/>
}