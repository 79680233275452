export const config = {
  app: {
    apiUri: process.env.REACT_APP_API_URI || 'http://localhost:4000',
    baseUri: process.env.REACT_APP_BASE_URI || 'http://localhost:3000',
    demo: process.env.REACT_APP_DEMO || false
  },
  meta: {
    append: ' - ChatADy',
    title: 'ChatADy',
    description: 'ChatADy is an Conversational Ad Exchange. Advertising should be conversational, not intrusive. We transform traditional advertising into interactive dialogues, connecting advertisers and chatbot developers.'
  },
}