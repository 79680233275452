import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';

import Loader from './Loader'
import Signup from './Signup'
import Signin from './Signin'
import Reset from './Reset'
import Forgot from './Forgot'
import Dashboardselect from './Dashboardselect'
import GettingStarted from './Gettingstarted'
import CampaignsList from './CampaignsList'
import PubDash from './PubDash'
import CreateUpdatePublisher from './CreateUpdatePublisher'
import CreateUpdateCampaign from './CreateUpdateCampaign'
import CampaignView from './CampaignView'
import CampaignPayment from './CampaignPayment'
import AdminVerificationsView from './AdminVerificationsView'
import AdminCampaignsView from './AdminCampaignsView'
import AdminPublishersView from './AdminPublishersView'
import AdminWithdrawalsView from './AdminWithdrawalsView'
import AdminView from './AdminView'
import WithdrawalsView from './WithdrawalsView'
import VerifyView from './VerifyView'
import IntegrationView from './IntegrationView'
import TestIntegrationView from './TestIntegrationView'
import EditProfile from './EditProfile'

const router = createBrowserRouter([{
  path: "/",
  element: <Loader/>
},{
  path: "/sign-up",
  element: <Signup/>
},{
  path: "/sign-in",
  element: <Signin/>
},{
  path: "/forgot",
  element: <Forgot/>
},{
  path: "/reset",
  element: <Reset/>
},{
  path: "/dashboard-select",
  element: <Dashboardselect/>
},{
  path: "/getting-started",
  element: <GettingStarted/>
},{
  path: "/create-publisher",
  element: <CreateUpdatePublisher/>
},{
  path: "/p/",
  element: <PubDash/>
},{
  path: "/p/:id?",
  element: <PubDash/>
},{
  path: "/p/:id/edit",
  element: <CreateUpdatePublisher/>
},{
  path: "/p/:id/integration",
  element: <IntegrationView/>
},{
  path: "/p/:id/testintegration",
  element: <TestIntegrationView/>
},{
  path: "/p/:id/withdrawals",
  element: <WithdrawalsView/>
},{
  path: "/p/:id/verify",
  element: <VerifyView/>
},{
  path: "/a/create-campaign",
  element: <CreateUpdateCampaign/>
},{
  path: "/a",
  element: <CampaignsList/>
},{
  path: "/a/active",
  element: <CampaignsList/>
},{
  path: "/a/stopped",
  element: <CampaignsList/>
},{
  path: "/a/completed",
  element: <CampaignsList/>
},{
  path: "/a/review",
  element: <CampaignsList/>
},{
  path: "/a/campaign/:id?",
  element: <CampaignView/>
},{
  path: "/a/campaign/:id/payment",
  element: <CampaignPayment/>
},{
  path: "/a/campaign/:id/edit",
  element: <CreateUpdateCampaign/>
},{
  path: "/admin/verifications",
  element: <AdminVerificationsView/>
},{
  path: "/admin/withdrawals",
  element: <AdminWithdrawalsView/>
},{
  path: "/admin/campaigns",
  element: <AdminCampaignsView/>
},{
  path: "/admin/publishers",
  element: <AdminPublishersView/>
},{
  path: "/admin",
  element: <AdminView/>
},{
  path: "/edit-profile",
  element: <EditProfile/>
}]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
