import React, { Component } from 'react'
import { Alert, Col, Container, Row, Form, Card, Button } from 'react-bootstrap'
import Dash from './Dash'
import { config } from './config'
import { getCredentials } from './utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import FeatherIcon from 'feather-icons-react'
import Header from './components/Header'

interface CreateUpdatePublisherProps {
  navigate: any
  params: any
}

type CreateUpdatePublisherStates = {
  publishers: any[]
  error: string
  name: string
  url: string
  description: string
  adultcontent: boolean
  minbid: string
}

class CreateUpdatePublisher extends Component <CreateUpdatePublisherProps, CreateUpdatePublisherStates> {
  constructor (props: CreateUpdatePublisherProps) {
    super(props)
    this.state = {
      publishers: [],
      error: '',
      name: '',
      url: '',
      description: '',
      adultcontent: false,
      minbid: '1.00'
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as CreateUpdatePublisherStates)
  }


  componentDidMount () {
    this.loadPublishers()
  }

  loadPublishers = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          if (json.publishers.length > 0) {
            this.setState({
              publishers: json.publishers
            }, () => {
              if (this.props.params.id === 'xxxxx' && json.publishers.length > 0) {
                this.props.navigate('/p/' + json.publishers[0].id + '/edit')
              } else {
                this.loadPublisher()
              }
            })
          } else {
            this.props.navigate('/create-publisher')
          }
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  componentDidUpdate(prevProps: Readonly<CreateUpdatePublisherProps>, prevState: Readonly<CreateUpdatePublisherStates>, snapshot?: any): void {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadPublisher()
    }
  }

  loadPublisher = () => {
    if (!this.props.params.id || this.props.params.id === 'xxxxx') {
      return
    }
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/publisher/' + this.props.params.id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            name: json.publisher.name,
            url: json.publisher.url,
            description: json.publisher.description,
            adultcontent: json.publisher.adultcontent,
            minbid: (parseInt(json.publisher.minbid) / 100).toString()
          })
        }
      })
  }

  createUpdatePublisher = () => {
    const { token } = getCredentials()
    const update = this.props.params.id && this.props.params.id !== 'xxxxx' ? '/'+this.props.params.id : ''
    fetch(
      config.app.apiUri + '/api/v1/publisher' + update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          name: this.state.name,
          url: this.state.url,
          description: this.state.description,
          adultcontent: this.state.adultcontent,
          minbid: Math.floor(parseFloat(this.state.minbid)*100)
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          if (this.props.params.id) {
            this.props.navigate('/p/'+this.props.params.id)
          } else {
            this.props.navigate('/p/'+json.id+'/testintegration')
          }
        }
        if (json.status === 'error') {
          this.setState({ error: json.message })
        }
      })
  }

  render() {
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12} lg={10} xl={8}>
                <Header className="mt-md-5">
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>publisher</Header.Pretitle>
                        <Header.Title>{this.props.params.id && this.props.params.id !== 'xxxxx' ? "Update Publisher" : "Create a New Publisher"}</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <form className="mb-4">
                  <div className="form-group">
                    <Form.Label>Publisher name</Form.Label>
                    <Form.Control value={this.state.name} onChange={this.inputChange} name="name" type="text" />
                  </div>
                  <div className="form-group">
                    <Form.Label>Publisher website url</Form.Label>
                    <Form.Control value={this.state.url} onChange={this.inputChange} name="url" type="text" />
                  </div>
                  <div className="form-group">
                    <Form.Label className="mb-1">Publisher description</Form.Label>
                    <Form.Text className="text-muted">Describe your platform and what kind of conversations will there be</Form.Text>
                    <Form.Control value={this.state.description} onChange={this.inputChange} name="description" as="textarea" rows={3} />
                  </div>
                  <hr className="mt-4 mb-5" />
                  <div className="form-group">
                    <Form.Label className="mb-1">Minimal bid</Form.Label>
                    <Form.Text className="text-muted">Receive only assets with value bigger than minimal bid (1.00 is min)</Form.Text>
                    <Form.Control value={this.state.minbid} onChange={this.inputChange} name="minbid"/>
                  </div>
                  <div className="form-group">
                    <Form.Label className="mb-1">Adult content</Form.Label>
                    <Form.Text className="text-muted">
                      If platform is focused purely on adult content.
                    </Form.Text>
                    <Form.Switch defaultChecked={this.state.adultcontent} onChange={()=>this.setState({adultcontent: !this.state.adultcontent})} name="adultcontent"/>
                  </div>
                  <hr className="mt-5 mb-5" />
                  <Card className="bg-light border">
                    <Card.Body>
                      <h4 className="mb-2">
                        <FeatherIcon icon="alert-triangle" size="1em" /> Warning
                      </h4>
                      <p className="small text-muted mb-0">After publisher creation and once integration is complete, the process of review is started. Review can take up to few days depending on availability of our team.</p>
                    </Card.Body>
                  </Card>
                  <hr className="mt-5 mb-5" />
                  { this.state.error !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({error: ''})} dismissible>
                      {this.state.error}
                    </Alert>
                  :null}
                  <Button className="w-100" onClick={()=>this.createUpdatePublisher()}>
                    {this.props.params.id && this.props.params.id !== 'xxxxx' ? "Update publisher" : "Create Publisher"}
                  </Button>
                  <Button variant="link" className="w-100 text-muted" onClick={()=>this.props.navigate('/p')}>
                    {this.props.params.id && this.props.params.id !== 'xxxxx' ? "Cancel update" : "Cancel creation"}
                  </Button>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function CreateUpdatePublisherWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <CreateUpdatePublisher navigate={navigate} params={params}/>
}