import React, { Component } from 'react';
import { Col, Container, Row, Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import { Alert } from 'react-bootstrap';

interface ResetProps {
  navigate: any
  searchParams: any
}

type ResetStates = {
  logError: string
  logErrors: any
  logMessage: string
  logPassword: string
  logPassword2: string
}

class Reset extends Component <ResetProps, ResetStates> {
  constructor (props: ResetProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logMessage: '',
      logPassword: '',
      logPassword2: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as ResetStates)
  }

  componentDidMount(): void {
  }

  reset = (e:any) => {
    e.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/me/reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          password: this.state.logPassword,
          uid: this.props.searchParams[0].get('key')
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            logMessage: json.message
          })
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5">
              <>
                <h1 className="display-4 text-center mb-3">Reset Password</h1>
                <p className="text-muted text-center mb-5">Reset your password.</p>
                <form onSubmit={(e)=>this.reset(e)}>
                  <div className="form-group">
                    <Row>
                      <Col>
                        <Form.Label>Password</Form.Label>
                      </Col>
                    </Row>
                    <Form.Control type="password" value={this.state.logPassword} name="logPassword" onChange={this.inputChange} placeholder="Enter your new password" />
                    <Row className='pt-4'>
                      <Col>
                        <Form.Label>Re-enter Password</Form.Label>
                      </Col>
                    </Row>
                    <Form.Control type="password" value={this.state.logPassword2} name="logPassword2" onChange={this.inputChange} placeholder="Re-enter your new password" />
                  </div>
                  <Button size="lg" className="w-100 mb-3" type="submit">
                    Set New Password
                  </Button>
                  { this.state.logError !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({logError: ''})} dismissible>
                      {this.state.logError}
                    </Alert>
                  :null}
                  { this.state.logMessage !== '' ?
                    <Alert variant="success" className='text-center' onClose={() => this.setState({logMessage: ''})} dismissible>
                      {this.state.logMessage}
                    </Alert>
                  :null}
                  <p className="text-center">
                    <small className="text-muted text-center">
                      Sign in? <Link to="/sign-in">Sign In!</Link>.
                    </small>
                  </p>
                </form>
              </>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default function ResetWithNavigation() {
  const navigate = useNavigate()
  const searchParams = useSearchParams()
  return <Reset navigate={navigate} searchParams={searchParams}/>
}