import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ChartDefaults from './ChartDefaults';

//
// Chart
//

// @ts-ignore
const Chart = (props: any) => {
  const classes = classNames('chart', props.layout && `chart-${props.layout}`, props.size && `chart-${props.size}`, props.className);

  useEffect(function () {
    ChartDefaults();
    
    return function () {
      const tooltip = document.getElementById('chart-tooltip');

      if (tooltip && tooltip.parentNode) {
        tooltip.parentNode.removeChild(tooltip);
      }
    };
  }, []);

  return <div className={classes} {...props}/>;
}

//
// Chart legend
//

const ChartLegend = ({ data, className, ...props }: any) => {
  const classes = classNames('chart-legend', className);

  const items = data.labels.map(function (label: any, index: any) {
    const color = data.datasets[0].backgroundColor[index];

    return <ChartLegendItem color={color} label={label} key={index} />;
  });

  return (
    <div className={classes} {...props}>
      {items}
    </div>
  );
}

//
// Chart legend item
//

const ChartLegendItem = ({ color, label }: any) => {
  return (
    <div className="chart-legend-item">
      <ChartLegendIndicator color={color} /> {label}
    </div>
  );
}

//
// Chart legend indicator
//

const ChartLegendIndicator = ({ color }: any) => {
  return <i className="chart-legend-indicator" style={{ backgroundColor: color }} />;
}

export { Chart, ChartLegend };
