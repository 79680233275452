import React, { Component } from 'react';
import { Col, Container, Row, Card, Dropdown, InputGroup, Form, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import { getCredentials } from './utils'
import Dash from './Dash'
import Header from './components/Header'

interface AdminWithdrawalsViewProps {
  navigate: any
}

type AdminWithdrawalsViewStates = {
  search: string
  withdrawals: any[]
}

class AdminWithdrawalsView extends Component <AdminWithdrawalsViewProps, AdminWithdrawalsViewStates> {
  constructor (props: AdminWithdrawalsViewProps) {
    super(props)
    this.state = {
      search: '',
      withdrawals: []
    }
  }

  componentDidMount () {
    this.loadWithdrawals()
  }

  loadWithdrawals = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/withdrawals', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === "success") {
          this.setState({
            withdrawals: json.withdrawals
          })
        }
        if (json.status === "nouser") {
          this.props.navigate('/sign-in')
        }
      })
  }

  searchChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as AdminWithdrawalsViewStates, ()=>this.loadWithdrawals())
  }

  processed = (id: string) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/admin/withdrawals/' + id + '/processed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadWithdrawals()
        }
        if (json.status === 'no access') {
          this.props.navigate('/sign-in')
        }
      })
  }

  render() {
    const { token } = getCredentials()
    return (
      <Dash>
        <div className="main-content">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Header>
                  <Header.Body>
                    <Row className="align-items-center">
                      <Col>
                        <Header.Pretitle>Admin</Header.Pretitle>
                        <Header.Title className="text-truncate">Withdrawals</Header.Title>
                      </Col>
                    </Row>
                  </Header.Body>
                </Header>
                <Card>
                  <Card.Header>
                    <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                      <Form.Control type="search" placeholder="Search" name="search" value={this.state.search} onChange={this.searchChange}/>
                      <InputGroup.Text>
                        <FeatherIcon icon="search" size="1em" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Card.Header>
                  <Table size="sm" className="card-table table-nowrap">
                    <thead>
                      <tr>
                        <th>publisher</th>
                        <th>amount</th>
                        <th>items</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.withdrawals.map((withdraw: any)=>
                        <tr key={withdraw.id}>
                          <td /*onClick={()=>this.props.navigate('/a/campaign/'+publisher.id)} style={{cursor: "pointer"}}*/>
                            {withdraw.publisher.name} (<Link to={'mailto:'+withdraw.publisher.user.email}>{withdraw.publisher.user.email}</Link>)
                            <div className='small text-muted'>{withdraw.publisher.description}</div>
                          </td>
                          <td>
                            ${withdraw.amount/100} (${withdraw.effective/100})
                          </td>
                          <td>
                            {withdraw.publisher.verifications.map((verification: any) => {
                              return <div>
                                {verification.type === 'Text item' ? <div><div>{verification.title}:</div><div>{verification.data}</div></div> : null}
                                {verification.type === 'File item' ? <div><div>{verification.title}:</div><div><a href={config.app.apiUri + '/api/v1/admin/file/' +  verification.data + '?key=' + token} target='blank'>Open document</a></div></div> : null}
                                {verification.type === 'Image item' ? <div><div>{verification.title}:</div><div><a href={config.app.apiUri + '/api/v1/admin/file/' + verification.data + '?key=' + token} target='blank'><img height={50} width={50} src={config.app.apiUri + '/api/v1/admin/file/' + verification.data + '?key=' + token} alt="" /></a></div></div> : null}
                              </div>
                            })}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle variant="light">Action</Dropdown.Toggle>
                              <Dropdown.Menu>
                               <Dropdown.Item key={withdraw.id} onClick={()=>this.processed(withdraw.id)}>Processed ${withdraw.effective/100}</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Dash>
    );
  }
}

export default function AdminWithdrawalsViewWithNavigation() {
  const navigate = useNavigate()
  return <AdminWithdrawalsView navigate={navigate}/>
}